<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="crmCustomers"
                          base-table="customer"
                          columnsPrefix="common.column.crm_customers."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.title.crm_customers"
                          :actions-list="getActions()"
                >
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="$root.$children[0].openModal('todo-modal', {customer_id: row.item.id}, refreshTable)">
                                {{ $t('common.button.add_todo') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="getModules().includes('crm-customer-reports') && $root.hasAccess('crm-customer-reports')"
                                @click="$root.$children[0].openModal('customer-reports', {customerId: row.item.id})">
                                {{ $t('common.button.reports') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'CrmCustomers',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Crm', ['getCustomersTable']),
        ...mapGetters('CommonData', ['getModules','getWorkflowTriggers']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Crm/fetchCustomers', 'getCustomersTable');
        },
        ctxBaseParams() {
            return {
                'startDate': this.dateStart,
                'endDate': this.dateEnd,
            }
        },
        openChangeAttribute() {
            this.$root.$children[0].openModal('change-attribute-modal', {
                type: 'customer',
                selectedData: this.getSelectedData('crmCustomers'),
            }, this.refreshTable, {width: '800px'})
        },
        sendMails() {
            const selected = this.selected.length || this.selectedSource != 'selected'
            this.$root.$children[0].openModal('emails-modal', {
                type: selected ? 'customer' : null,
                selectedData: selected ? this.getSelectedData('crmCustomers') : null,
            }, this.refreshTable, {width: '800px'})
        },
        getActions() {
            let actions = [
                {
                    title: this.$t('common.button.createCustomer'),
                    icon: 'plus',
                    hide: !this.$root.hasAccess('customers', 'create'),
                    click: () => {
                        this.$root.$children[0].openModal('customer-modal', {}, this.refreshTable)
                    },
                },
                {
                    title: this.$t('article.button.change_attribute'),
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    icon: 'list',
                    hide: !this.$root.hasAccess('customers', 'create'),
                    click: () => {
                        this.openChangeAttribute()
                    }
                },
                {
                    title: this.$t('common.button.send_email'),
                    icon: 'envelope',
                    click: () => {
                        this.sendMails()
                    }
                },
            ]

            this.getWorkflowTriggers()['customer'].forEach(trigger => {
                actions.push({
                    title: 'Trigger: ' + trigger.name,
                    icon: 'play',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    click: () => {
                        this.loading = true

                        this.$store.dispatch('Workflow/executeTrigger', {
                            id: trigger.id,
                            ...this.getSelectedData('crmCustomers')
                        })
                            .then((response) => {
                                if (response.data.text) {
                                    this.$root.showMessage('Processing...', response.data.text, 'info')
                                    this.$root.$children[0].showJobsToast(response.data.result.batch_group)
                                }
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    },
                },);
            })

            return actions
        }
    },
}
</script>